import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "d-flex flex-row justify-content-between align-items-center mb-4" }
const _hoisted_3 = { class: "red w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WineCard = _resolveComponent("WineCard")!
  const _component_Table = _resolveComponent("Table")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString($props.action.name), 1),
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "divider w-100 d-none d-md-block" }, null, -1)),
      _createElementVNode("h3", _hoisted_3, " Loopt tot " + _toDisplayString($setup.moment($props.action.end).locale('nl').format('DD MMMM')), 1),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "divider w-100" }, null, -1))
    ]),
    ($props.action.items.length > 0)
      ? (_openBlock(), _createBlock(_component_Table, { key: 0 }, {
          items: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.action.items, (wine) => {
              return (_openBlock(), _createBlock(_component_WineCard, {
                key: wine.id,
                wine: wine,
                action: $props.action.description,
                onWineClick: ($event: any) => ($setup.openWine(wine))
              }, null, 8, ["wine", "action", "onWineClick"]))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ($props.action.items.length > 0 && $props.action.total - $props.action.currentCount !== 0)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 1,
          text: $setup.buttonText,
          design: "primary",
          onButtonClick: $setup.loadMore
        }, null, 8, ["text", "onButtonClick"]))
      : _createCommentVNode("", true)
  ]))
}