import { Type } from 'class-transformer';
import { Wine } from '@/shared/models/Wine';

export class Action {
    id!: string;
    name!: string;
    start!: string;
    end!: string;
    page!: number | 0;
    currentCount!: number | 0;
    total!: number | 0;
    metadata!: any;
    description!: string;

    @Type(() => Wine)
    items!: Wine[];
}
