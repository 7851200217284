import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionItem = _resolveComponent("ActionItem")!
  const _component_PageFooter = _resolveComponent("PageFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "text-start" }, " Acties ", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.actions, (action) => {
      return (_openBlock(), _createBlock(_component_ActionItem, {
        key: action.id,
        action: action
      }, null, 8, ["action"]))
    }), 128)),
    _createVNode(_component_PageFooter, {
      footer: "Heeft u een vraag over onze acties?",
      button: $setup.button,
      font: "martel",
      onButtonClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.button.callBack()))
    }, null, 8, ["button"])
  ]))
}