
import { plainToClass } from 'class-transformer';
import moment from 'moment';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import WineCard from '@/components/WineCard.vue';
import Button from '@/shared/components/Button.vue';
import Table from '@/shared/components/Table.vue';
import { Action } from '@/shared/models/Action';
import { Wine } from '@/shared/models/Wine';

export default {
    props: {
        action: {
            type: Action,
            required: true,
        },
    },
    components: {
        Button,
        WineCard,
        Table,
    },
    setup(props: any) {
        const actionRef = ref(props.action);
        const buttonText = computed(() => `TOON MEER (${actionRef.value.total - actionRef.value.currentCount})`);
        const router = useRouter();
        const store = useStore();

        function openWine(wine: any) {
            const id = String(wine.wine.id);
            router.push({ name: 'AssortimentDetail', params: { id } });
        }

        function loadMore() {
            actionRef.value.page += 1;
            store.dispatch('ACTIONS_GET_MORE', actionRef.value).then((res) => {
                res.data.items.forEach((wine: any) => {
                    actionRef.value.items.push(plainToClass(Wine, wine));
                });
                actionRef.value.currentCount = actionRef.value.items.length;
            });
        }

        return {
            openWine,
            moment,
            buttonText,
            loadMore,
        };
    },
};
