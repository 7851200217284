
import { plainToClass } from 'class-transformer';
import { ref } from 'vue';
import { useStore } from 'vuex';
import ActionItem from '@/components/ActionItem.vue';
import PageFooter from '@/shared/components/PageFooter.vue';
import { Action } from '@/shared/models/Action';
import { Wine } from '@/shared/models/Wine';

export default {
    components: {
        ActionItem,
        PageFooter,
    },
    setup() {
        const actions = ref(Array<Action>());
        const store = useStore();

        function getWines() {
            return new Promise((resolve) => {
                store.dispatch('ACTIONS_GET').then((res) => {
                    actions.value = plainToClass(Action, res.data.data as Action[]);
                    actions.value.map((action: Action) => {
                        action.page = 1;
                        action.currentCount = action.items.length;
                        action.total = action.metadata.total;

                        return action;
                    });

                    resolve();
                });
            });
        }

        getWines();

        const button = {
            text: 'Neem contact op',
            callBack: () => window.open('https://www.kwastwijnkopers.nl/contact/', '_blank'),
        };

        return {
            button,
            actions,
            getWines,
        };
    },
};
